import React from "react";
import JWTAuthAuthProvider from "./Contexts/AuthProvider/JWTAuthProvider";
import AuthRoutes from "./Utility/AuthRoutes";
import Routes from "./Routes/Routes";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { NotificationProvider } from "./Utility/CustomHooks/useNotification";

// const store = configureStore({ reducers: { employeeSlice: employeeSlice } });

function App() {
  return (
    <div>
      <Provider store={store}>
        <NotificationProvider>
          <JWTAuthAuthProvider>
            <AuthRoutes>{Routes}</AuthRoutes>
          </JWTAuthAuthProvider>
        </NotificationProvider>
      </Provider>
    </div>
  );
}

export default App;
