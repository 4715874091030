import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import jwtAxios, { setAuthToken } from "./index";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../Utility/CustomHooks/useNotification";

interface JWTAuthContextProps {
  // user: AuthUser | null | undefined;
  user: any;
  isAuthenticated: boolean;
  isLoading: boolean;
}

// interface SignUpProps {
//   name: string;
//   email: string;
//   password: string;
// }

// interface SignInProps {
//   email: string;
//   password: string;
// }

interface JWTAuthActionsProps {
  // signUpUser: (data: SignUpProps) => void;
  signInUser: (email: string, password: string) => void;
  // logout: () => void;
}

export const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
export const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  // signUpUser: () => {},
  signInUser: () => {},
  // logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const { showNotification } = useNotification();
  const [authCredentials, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const Navigate = useNavigate();
  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      console.log("token", token, "email", email, "......code......");
      if (!token || !email) {
        setJWTAuthData((prev) => ({
          ...prev,
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        }));

        console.log("has entered if no token");
        return;
      } else {
        setAuthToken(token);
        console.log("newer one");
        setJWTAuthData((prev) => ({
          ...prev,
          user: email,
          isLoading: false,
          isAuthenticated: true,
        }));
        console.log("values", authCredentials);
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async (email: string, password: string) => {
    try {
      const { data } = await jwtAxios.post("api/login", {
        email,
        password,
      });
      console.log("data url", process.env.React_App_BASE_URL);
      setAuthToken(data.token);
      localStorage.setItem("email", data.user.email);

      setJWTAuthData((prev) => ({
        ...prev,
        user: email,
        isLoading: false,
        isAuthenticated: true,
      }));
      Navigate("/dashboard");
    } catch (error: any) {
      if (error.response?.data.message) {
        showNotification(error.response.data.message, {
          variant: "error",
        });
      }
      setJWTAuthData({
        ...authCredentials,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  // const signUpUser = async ({
  //   name,
  //   email,
  //   password,
  // }: {
  //   name: string;
  //   email: string;
  //   password: string;
  // }) => {
  //   try {
  //     const { data } = await jwtAxios.post("users", { name, email, password });
  //     localStorage.setItem("token", data.token);
  //     setAuthToken(data.token);
  //     const res = await jwtAxios.get("/auth");
  //     console.log("value of dta ,,,,,1st");
  //     setJWTAuthData((prev) => ({
  //       ...prev,
  //       user: email,
  //       isLoading: false,
  //       isAuthenticated: true,
  //     }));
  //   } catch (error) {
  //     setJWTAuthData({
  //       ...authCredentials,
  //       isAuthenticated: false,
  //       isLoading: false,
  //     });
  //   }
  // };

  // const logout = async () => {
  //   localStorage.removeItem("token");
  //   setAuthToken();
  //   setJWTAuthData({
  //     user: null,
  //     isLoading: false,
  //     isAuthenticated: false,
  //   });
  // };

  return (
    <JWTAuthContext.Provider
      value={{
        ...authCredentials,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
