import jwtAxios from "../Contexts/AuthProvider";

export async function getRequest(
  url: string,
  method: string,
  body = null,
  headers = null,
  params: any
) {
  const data = await jwtAxios({
    method: method.toLowerCase(), // Convert to lowercase
    url,
    data: JSON.parse(body || "{}"),
    headers: JSON.parse(headers || "{}"),
    params: params,
  })
    .then((res: any) => {
      console.log(res, "res fun");
      return res.data;
    })
    .catch((err: any) => {
      console.log("Error", err);
    });
  return data;
}
