import React from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import HRLogin from "../Containers/HRLogin/HRLogin";
import Dashboard from "../Components/Dashboard/DashboardComponent";
import EmployeeProfile from "../Components/EmployeeProfie/EmployeeProfile";
import SideBar from "../Components/Sidebar/Sidebar";
import { useAuthUser } from "./../Utility/AuthHooks"; // Import your custom authentication hook
import EmployeeForm from "../Components/EmployeeForm/EmployeeForm";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const { isAuthenticated } = useAuthUser();

    // Redirect to sign-in if not authenticated
    if (!isAuthenticated) {
      return <Navigate to="/sign-in" replace />;
    }

    // Render the wrapped component if authenticated
    return <WrappedComponent {...props} />;
  };
};

// Wrap your components with the authentication HOC
const AuthenticatedDashboard = withAuth(Dashboard);
const AuthenticatedEmployeeProfile = withAuth(EmployeeProfile);

function Routes() {
  const { isAuthenticated } = useAuthUser();

  const element = useRoutes([
    {
      path: "sign-in",
      element: <HRLogin />,
    },
    {
      path: "dashboard",
      element: <AuthenticatedDashboard />,
    },
    {
      path: "Employee/:id",
      element: <AuthenticatedEmployeeProfile />,
    },
    {
      path: "EmployeeForm",
      element: <EmployeeForm />,
    },
    {
      path: "*",
      element: <Navigate to="/sign-in" replace />,
    },
  ]);
  const noBarRoutes = ["/sign-in"];
  const location = useLocation();

  // Access the current URL from the location object
  const currentUrl = location.pathname;

  // Log or use the current URL as needed
  console.log("Current URL:", currentUrl, !noBarRoutes.includes(currentUrl));

  console.log("isAuthenticated", isAuthenticated);

  // Render the Sidebar only if authenticated
  return (
    <React.Fragment>
      {isAuthenticated && !noBarRoutes.includes(currentUrl) && <SideBar />}
      {element}
    </React.Fragment>
  );
}

export default Routes;
