// useNotification.ts

import { useSnackbar, SnackbarProvider, VariantType } from "notistack";
import React, { ReactNode } from "react";

interface NotificationOptions {
  variant?: VariantType;
}

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message: string, options?: NotificationOptions) => {
    const { variant = "default" } = options || {};

    enqueueSnackbar(message, { variant });
  };

  return {
    showNotification,
  };
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>;
