// employeeSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import jwtAxios from "../../Contexts/AuthProvider";

interface EmployeeFormValues {
  name: string;
  email?: string;
  gender?: string;
  dob?: string;
  join_date?: string;
  probation_period?: string;
  designation?: string;
  line_manager?: string;
  contact_number?: string;
}

interface ItemsState {
  employees: EmployeeFormValues[];
  status: string;
  error: string | null;
}

const initialState: ItemsState = {
  employees: [],
  status: "idle",
  error: null,
};

const createEmployee = async (userData: any) => {
  console.log("received user data in ", userData);
  const response = await jwtAxios.post(`api/employee-store`, userData);
  console.log("response after creation", response);
  return response.data.employee;
};

const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (employee: EmployeeFormValues, thunkAPI) => {
    try {
      const addedEmployee = await createEmployee(employee);
      return addedEmployee;
    } catch (error: any) {
      console.log("eeeeeeeerrrrr", error);
      return thunkAPI.rejectWithValue({
        errorMessage: Object.values(error.response.data.errors),
      });
    }
  }
);

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmployee.pending, (state) => {
        state.status = "loading";
        console.log("loading.............");
      })
      .addCase(
        addEmployee.fulfilled,
        (state, action: PayloadAction<EmployeeFormValues>) => {
          console.log("data before update", state);
          state.status = "succeeded";
          state.employees.push(action.payload);
          console.log("data updated", state);
        }
      )
      .addCase(addEmployee.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload
          ? action.payload.errorMessage
          : "An error occurred";
        throw action.payload.errorMessage;
      });
  },
});

export { addEmployee };
export default employeeSlice.reducer;
