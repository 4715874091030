import {
  useJWTAuth,
  useJWTAuthActions,
} from "../Contexts/AuthProvider/JWTAuthProvider";
// import { getUserFromFirebase } from "./helper/AuthHelper";

export const useAuthUser = () => {
  // const {user, isAuthenticated, isLoading} = useFirebase();
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: user,
  };
};

export const useAuthMethod = () => {
  const { signInUser } = useJWTAuthActions();

  return {
    signInUser,
  };
};
