// HRLogin.tsx

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthMethod } from "./../../Utility/AuthHooks";
import "./HRLogin.scss";

const HRLogin: React.FC = () => {
  const { signInUser } = useAuthMethod();

  const initialValues = { email: "", password: "" };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values: { email: string; password: string }) => {
    // Update the method to use email instead of username
    signInUser(values.email, values.password);
  };

  return (
    <div className="MainContainer">
      <div className="login-container">
        <h1 className="login-header">HR Login</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="login-form">
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Field
                type="text"
                id="email"
                name="email"
                className="form-input"
              />
            </div>
            <ErrorMessage
              name="email"
              component="div"
              className="errorMessage"
            />

            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                className="form-input"
              />
            </div>
            <ErrorMessage
              name="password"
              component="div"
              className="errorMessage"
            />

            <button type="submit" className="login-button">
              Login
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default HRLogin;
