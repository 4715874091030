// EmployeeForm.jsx

import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { addEmployee } from "./../../Redux/Slices/createEmployeeSlice";

import "./EmployeeForm.scss"; // Styling for EmployeeForm
import { useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";
import { useNotification } from "../../Utility/CustomHooks/useNotification";

export interface EmployeeFormValues {
  name: string;
  email?: string;
  gender?: string;
  dob?: string;
  join_date?: string;
  probation_period?: string;
  designation?: string;
  line_manager?: string;
  contact_number?: string;
}

interface FormFieldProps {
  label: string;
  name: keyof EmployeeFormValues;
  type: string;
  placeholder: string;
  required: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  type,
  placeholder,
  required,
}) => (
  <div className="form-group">
    <label htmlFor={name} className="label">
      {label}:
    </label>
    {type === "text" || type === "email" || type === "number" ? (
      <Field
        type={type}
        id={name.toString()}
        name={name}
        placeholder={placeholder}
        required={required}
      />
    ) : type === "date" ? (
      <Field
        type="date"
        id={name.toString()}
        name={name}
        placeholder={placeholder}
        required={required}
      />
    ) : (
      <Field
        as="select"
        id={name.toString()}
        name={name}
        className={type === "select" ? "label-dropdown" : ""}
      >
        <option value="">Select {label}</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="Other">Other</option>
      </Field>
    )}
    <ErrorMessage name={name.toString()} component="div" />
  </div>
);

const EmployeeForm: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const employees = useSelector((state: any) => state);
  const { showNotification } = useNotification();

  const initialValues: EmployeeFormValues = {
    name: "",
    email: "",
    gender: "",
    dob: "",
    join_date: "",
    probation_period: "",
    designation: "",
    line_manager: "",
    contact_number: "",
  };

  const formFields: FormFieldProps[] = [
    {
      label: "Name *",
      name: "name",
      type: "text",
      placeholder: "ABC etc",
      required: true,
    },
    {
      label: "Email *",
      name: "email",
      type: "email",
      placeholder: "ABC@gmail.com etc",
      required: true,
    },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      placeholder: "",
      required: false,
    }, // Change type to "select"
    {
      label: "Date of Birth",
      name: "dob",
      type: "date",
      placeholder: "yyyy-mm-dd like 2023-10-24 ",
      required: false,
    },
    {
      label: "Joining Date",
      name: "join_date",
      type: "date",
      placeholder: "yyyy-mm-dd like 2023-10-24 ",
      required: false,
    },
    {
      label: "Probation Period",
      name: "probation_period",
      type: "number",
      placeholder: "3",
      required: false,
    },
    {
      label: "Designation",
      name: "designation",
      type: "text",
      placeholder: "Software Engineer",
      required: false,
    },
    {
      label: "Line Manager",
      name: "line_manager",
      type: "text",
      placeholder: "Subhan Wahid",
      required: false,
    },
    {
      label: "Contact Number *",
      name: "contact_number",
      type: "text",
      placeholder: "+923055710727",
      required: true,
    },
    // Add more fields as needed
  ];

  const onSubmit = async (values: EmployeeFormValues, { resetForm }: any) => {
    try {
      values.probation_period = String(values.probation_period);
      await dispatch(addEmployee(values));
      resetForm();
    } catch (error: any) {
      if (error.length) {
        error.forEach((message: string) => {
          console.log("error recieved", message);
          showNotification(message[0] || "An error occurred", {
            variant: "error",
          });
        });
      }
    }
  };

  console.log("data of the employees", employees);

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="Employee-formik">
          <div className="employee-form">
            {formFields.map((field) => (
              <FormField key={field.name} {...field} />
            ))}
          </div>
          <div className="button-container">
            <button type="submit" className="submit-button">
              Submit
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default EmployeeForm;
