import * as React from "react";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Grid from "../Grid/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useParams } from "react-router-dom";
import useAxios from "../../Utility/CustomHooks/useAxios";
import AppLoader from "../../Core/AppLoader";
import { IconButton } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { getRequest } from "../../Utility/APIs";
// const columns: GridColDef[] = [
//   {
//     field: "employeeId",
//     headerName: "Employee ID",
//     width: 120,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "employeeName",
//     headerName: "Employee Name",
//     width: 300,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "checkInTime",
//     headerName: "Check In Time",
//     width: 200,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "checkOutTime",
//     headerName: "Check Out Time",
//     width: 200,
//     flex: 25,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "hoursDifference",
//     headerName: "Hours Difference",
//     type: "number",
//     width: 180,
//     flex: 25,
//     align: "center",
//     headerAlign: "center",

//     valueGetter: (params: GridValueGetterParams) => {
//       const checkInTime = new Date(`2023-12-01 ${params.row.checkInTime}`);
//       const checkOutTime = new Date(`2023-12-01 ${params.row.checkOutTime}`);
//       const differenceInMilliseconds =
//         checkOutTime.getTime() - checkInTime.getTime();
//       const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
//       return differenceInHours.toFixed(2);
//     },
//   },
//   {
//     field: "date",
//     headerName: "Date",
//     width: 300,
//     flex: 25,
//     align: "center",
//     headerAlign: "center",
//     valueFormatter: (params: GridValueFormatterParams) => {
//       const date = new Date(params.value as string);
//       return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
//     },
//   },
// ];

const EmployeeProfile: React.FC = () => {
  const URLID = useParams();
  const [value, setValue] = React.useState<DateRange<unknown> | undefined>(
    undefined
  );

  const { response: EmployeeResponse, loading: LoadingEmployee } = useAxios({
    url: `api/employees/${URLID.id}`, // Replace with your API endpoint
    method: "GET",
    body: null, // If you don't have a request body, set it to null
    headers: null,
  });
  const employeeAttendanceData =
    EmployeeResponse?.employee_attendance?.data || [];

  const [rowsData, setRowsData] = React.useState<any>(employeeAttendanceData);

  React.useEffect(() => {
    if (EmployeeResponse && EmployeeResponse.employee_attendance?.data.length) {
      setRowsData(EmployeeResponse.employee_attendance.data);
    } else {
      setRowsData([]);
    }
  }, [EmployeeResponse]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  function BasicDateRangePicker() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateRangePicker"]}>
          <DateRangePicker
            localeText={{ start: "Check-in", end: "Check-out" }}
            value={value}
            onChange={(newValue: any) => {
              setValue(newValue);
              filterDate(newValue);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  if (LoadingEmployee) {
    // Loading state
    return <AppLoader />;
  }

  // if (errorEmployee || !EmployeeResponse?.employee) {
  //   // Error state
  //   return <h1>Error loading employee data</h1>;
  // }
  const ExtractDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short", // "long" for full month name
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // const rowsData = EmployeeResponse.employee_attendance.data || [];
  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const columns: GridColDef[] = [
    {
      field: "checkin_time",
      headerName: "Check In Time",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null) {
          return "Missed";
        }
        const checkInTime = new Date(params.value);
        return formatTime(checkInTime);
      },
    },
    {
      field: "checkout_time",
      headerName: "Check Out Time",
      width: 200,
      flex: 25,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null) {
          return "Missed";
        }
        const checkOutTime = new Date(params.value);
        return formatTime(checkOutTime);
      },
    },

    {
      field: "checkin_date",
      headerName: "Date",
      width: 300,
      flex: 25,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return ExtractDate(params.row.checkin_time);
      },
    },
  ];

  // const employeeName = LoadingEmployee ? EmployeeResponse?.employee?.name : "";
  // console.log("employeeNAme", employeeName, EmployeeResponse);
  async function filterDate(Data: any) {
    const response = await getRequest(
      `api/employees/${URLID.id}`, // Replace with your API endpoint
      "GET",
      null,
      null,
      {
        is_attendance: true,
        start_date: Data[0] ? convertAndIncreaseDate(Data[0]) : "null",
        end_date: Data[1] ? convertAndIncreaseDate(Data[1]) : "null",
        page: paginationModel.page + 1,
      }
    );
    setRowsData(response?.employee_attendance.data || []);
  }
  function convertAndIncreaseDate(inputDate: string): string | null {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date format");
      return null;
    }

    // Increase the date by one day
    const nextDay = new Date(parsedDate);
    nextDay.setDate(parsedDate.getDate());

    // Format the date as "YYYY-MM-DD"
    const formattedNextDay = `${nextDay.getFullYear()}-${(
      nextDay.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${nextDay.getDate().toString().padStart(2, "0")}`;

    return formattedNextDay;
  }
  const handlePaginationModelChange = async (newModel: any) => {
    setPaginationModel(newModel);
    const response = await getRequest(
      `api/employees/${URLID.id}`, // Replace with your API endpoint
      "GET",
      null,
      null,
      {
        page: newModel.page + 1,
      }
    );

    setRowsData((prev: any) => response?.employee_attendance.data || []);
  };

  const totalRows = EmployeeResponse?.employee_attendance?.total || 0;
  return (
    <Box sx={{ marginTop: "100px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%", // Ensure the container takes 100% width
          justifyContent: "space-around",
          backgroundColor: "secondary",
        }}
      >
        <BasicDateRangePicker />

        <IconButton
          aria-label="fingerprint"
          color="success"
          onClick={() => {
            setValue(undefined);
            filterDate({});
          }}
        >
          <FilterAltOffIcon />
        </IconButton>
      </Box>
      <br />
      <Grid
        title={EmployeeResponse.employee.name || "Employee Profile"}
        columns={columns}
        rows={rowsData}
        onPaginationModelChange={handlePaginationModelChange}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default EmployeeProfile;
