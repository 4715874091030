// Example in store.tsx
import { configureStore } from "@reduxjs/toolkit";
import employeeReducer from "./Slices/createEmployeeSlice"; // Import the reducer

const store = configureStore({
  reducer: {
    employee: employeeReducer, // Use the employee reducer
  },
});

export default store;

export type AppDispatch = typeof store.dispatch; // yeh store file me update krna hai
