import { useState, useEffect } from "react";
import { Method } from "axios";
import jwtAxios from "../../Contexts/AuthProvider";
import { useNotification } from "./useNotification";

interface UseAxiosProps {
  url: string;
  method: Method;
  body?: string | null;
  headers?: string | null;
  params?: any;
}

interface UseAxiosResult {
  response: any;
  error: string;
  loading: boolean;
  totalRows: number;
}

const useAxios = ({
  url,
  method,
  body = null,
  headers = null,
  params,
}: UseAxiosProps): UseAxiosResult => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState(0);

  const fetchData = () => {
    jwtAxios({
      method: method.toLowerCase(), // Convert to lowercase
      url,
      data: JSON.parse(body || "{}"),
      headers: JSON.parse(headers || "{}"),
      params: params,
    })
      .then((res: any) => {
        console.log("res", res, "res.data", res.data);
        setResponse(res.data);
        setTotalRows(res.data.meta.total);
      })
      .catch((err: any) => {
        console.log("error fffff", err);
        if (err.response?.data.message) {
          showNotification(err.response.data.message, {
            variant: "error",
          });
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers]);
  const { showNotification } = useNotification();
  return { response, error, loading, totalRows };
};

export default useAxios;
