import * as React from "react";
import {
  GridColDef,
  GridValueGetterParams,
  GridValueFormatterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Grid from "../Grid/Grid";
import useAxios from "../../Utility/CustomHooks/useAxios";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../Core/AppLoader";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, IconButton } from "@mui/material";
import { getRequest } from "../../Utility/APIs";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useAuthUser } from "../../Utility/AuthHooks";
const Dashboard: React.FC = () => {
  const { isAuthenticated } = useAuthUser();
  console.log("isAuthenticated dashboard", isAuthenticated);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const [value, setValue] = React.useState<DateRange<unknown> | undefined>(
    undefined
  );
  const { response, loading, totalRows } = useAxios({
    url: "api/employees", // Replace with your API endpoint
    method: "GET",
    body: null,
    headers: null,
    params: {
      current_page: paginationModel.page,
      per_page: paginationModel.pageSize,
    },
  });
  const [rowsData, setRowsData] = React.useState<any>([]);

  useEffect(() => {
    if (response && response.data) {
      setRowsData(response.data);
    }
  }, [response]);

  if (loading) {
    // Loading state
    return <AppLoader />;
  }
  // if (error || !response?.data) {
  //   // Error state
  //   return <h1>Error loading employees data</h1>;
  // }
  function convertAndIncreaseDate(inputDate: string): string | null {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date format");
      return null;
    }

    // Increase the date by one day
    const nextDay = new Date(parsedDate);
    nextDay.setDate(parsedDate.getDate());

    // Format the date as "YYYY-MM-DD"
    const formattedNextDay = `${nextDay.getFullYear()}-${(
      nextDay.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${nextDay.getDate().toString().padStart(2, "0")}`;

    return formattedNextDay;
  }

  async function filterDate(Data: any) {
    const response = await getRequest(
      "api/employees", // Replace with your API endpoint
      "GET",
      null,
      null,
      {
        name: "null",
        start_date: Data[0] ? convertAndIncreaseDate(Data[0]) : "null",
        end_date: Data[1] ? convertAndIncreaseDate(Data[1]) : "null",
      }
    );
    setRowsData(response.data);
  }
  const handlePaginationModelChange = async (newModel: any) => {
    setPaginationModel(newModel);
    const response = await getRequest(
      "api/employees", // Replace with your API endpoint
      "GET",
      null,
      null,
      {
        name: "null",
        start_date: "null",
        end_date: "null",
        page: newModel.page + 1,
      }
    );
    setRowsData(response.data);
  };
  function BasicDateRangePicker() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateRangePicker"]}>
          <DateRangePicker
            localeText={{ start: "Check-in", end: "Check-out" }}
            value={value}
            onChange={(newValue: any) => {
              setValue(newValue);
              filterDate(newValue);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Employee ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Employee Name",
      width: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        const handleClick = () => {
          // Use navigate to go to the "/employees" route
          navigate(`/Employee/${params.row.id}`);
        };

        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "checkin_time",
      headerName: "Check In Time",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null) {
          return "Missed";
        }
        const checkInTime = new Date(params.value);
        return formatTime(checkInTime);
      },
    },
    {
      field: "checkout_time",
      headerName: "Check Out Time",
      width: 200,
      flex: 25,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null) {
          return "Missed";
        }
        const checkOutTime = new Date(params.value);
        return formatTime(checkOutTime);
      },
    },
    {
      field: "hoursDifference",
      headerName: "Hours Difference",
      type: "number",
      width: 180,
      flex: 25,
      align: "center",
      headerAlign: "center",

      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.checkin_time === null) {
          return "Check In Missing ";
        }
        if (params.row.checkout_time === null) {
          return "Check Out Missing ";
        }

        return params.row.hours;
      },
    },
    {
      field: "checkin_date",
      headerName: "Date",
      width: 300,
      flex: 25,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.checkin_time === null
          ? "No data"
          : ExtractDate(params.row.checkin_time);
      },
    },
  ];
  const ExtractDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short", // "long" for full month name
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };
  return (
    <Box sx={{ marginTop: "100px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%", // Ensure the container takes 100% width
          justifyContent: "space-around",
          backgroundColor: "secondary",
        }}
      >
        <BasicDateRangePicker />

        <IconButton
          aria-label="fingerprint"
          color="success"
          onClick={() => {
            setValue(undefined);
            filterDate({});
          }}
        >
          <FilterAltOffIcon />
        </IconButton>
      </Box>
      <br />
      <Grid
        title="Dashboard"
        columns={columns}
        rows={rowsData}
        onPaginationModelChange={handlePaginationModelChange}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default Dashboard;
