import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
// Sample data, replace with API data

type AttendanceRecord = {
  id: number;
  name: string;
  checkin_time: string;
  checkout_time: string | null;
  hours: number | null;
  date: string;
};
interface GridProps {
  title: string;
  columns: GridColDef[];
  rows: AttendanceRecord[];
  onPaginationModelChange: (paginationModel: any) => void;
  totalRows: number;
}
const GridBoxStyle = {
  backgroundImage: `linear-gradient(to right, #8CC63F, #1FACE3)`,
  fontFamily: "Lato",
  color: " rgb(255 255 255)",
  textAlign: "center",
};
const FlexStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const DataGridStyles = {
  color: "rgb(255 255 255)",
  "& .MuiDataGrid-columnHeader": {
    fontSize: "16px",
    fontWeight: "1000",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiDataGrid-checkboxInput": {
    color: "rgb(255 255 255)",
  },
};
const PaginationModel = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
};

const Grid: React.FC<GridProps> = ({
  title,
  columns,
  rows,
  onPaginationModelChange,
  totalRows,
}) => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  return (
    <Box sx={GridBoxStyle}>
      <div
        style={{
          height: "7rem",
          ...FlexStyle,
        }}
      >
        <span
          style={{
            fontSize: "3.2rem", // Adjust the font size as needed
          }}
        >
          {title}
        </span>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={totalRows}
        sx={DataGridStyles}
        initialState={PaginationModel}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);
          onPaginationModelChange(newModel); // Call the callback function with the updated paginationModel
        }}
      />
    </Box>
  );
};

export default Grid;
